.btn-no-focus:focus {
    outline: none;
    box-shadow: none;
    border-radius: 1rem;
}
.pane-content {
    padding: 0 10px;
}

h3 {
    font-size: 1.2em;
    margin: 10px 0;
    padding: 0;
}

p {
    margin: 0;
    padding: 0;
}
