.multi-year-dropdown {
    border: none;
    border-bottom: 1px solid #000000;
    border-radius: 0;
    background-color: #FFFFFF !important;
    color: #000000 !important;
    box-shadow: 0 0 0 0 !important;
}

.multi-year-dropdown-menu {
    z-index: 1021;
}

.multi-year-table {
    max-height: 15rem;

    th {
        font-size: 0.7rem;
    }
}
