.month-axis-label {
    font-size: 10px;
    height: 12px;
    text-anchor: start;
}

.hour-axis-label {
    font-size: 10px;
    width: 35px;
    text-anchor: end;
}
