.result-sensitivity-table-header{

}
.result-sensitivity-table-main-header{

}
.result-sensitivity-table-sub-header{
    font-weight: normal;
    font-size: 12px;
}
.summary-chart-specific-date{
    .k-dateinput-wrap{
        .k-input{
            font-size: 0.8rem;
        }
    }

}
