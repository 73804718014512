
.popover {
    max-height: 424px;
    max-width: 510px;
    min-width: 294px;
    min-height: 150px
}

.popover-body {
    overflow: auto !important;
    min-height: 40px;
    max-height: 300px;
    bottom: -10px;
    line-height: 15px;
}

/* width */
.popover-body::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.popover-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
}

/* Handle */
.popover-body::-webkit-scrollbar-thumb {
    background: #939598;
    border-radius: 5px;
}

/* Handle on hover */
.popover-body::-webkit-scrollbar-thumb:hover {
    background: #939598;
}

.popover-body ul {
    position: relative;
    /* overflow: hidden; */
    line-height: 31px;
}

.popover .popover-body ul li p {
    position: relative;
    line-height: 21px;
    bottom: 0
}

// .popover-body p a  {position: absolute;
// bottom: 24px;    }
.popover-body p a {
    overflow: hidden;
}

.popover-footer {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.popover-footer p {
    margin: 0px;
}
