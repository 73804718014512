$icon-size: 40px;
$icon-margin: 20px;

.schematic-heading {
    color: #5b0428;
    text-align: center;
}

.schematic-container {
    border: solid 1px #ddd;
    max-height: 500px;
}

.schematic-icon-container {
    height: calc($icon-size + $icon-margin);
    max-width: calc($icon-size + $icon-margin);
}

.schematic-icon {
    height: $icon-size;
    width: $icon-size;
}

.schematic-text {
    font-size: 0.7rem;
    text-align: center;
}

.schematic-label {
    font-size: 7px;
    text-align: center;
    transform: translateX(calc(-1 * $icon-margin / 2));
}

.vertical-offset {
    margin-bottom: $icon-margin;
}
