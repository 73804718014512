.result-side-nav-main-container {
    .result-side-nav-main-container-row {
        .navigation-menu-col {
            padding-right: 0;

            .side-nav-ul-common-list-style {
                /*                background: #EDEDED;
*/
                .side-nav-li-overview-list-style {
                    background-color: #FFFFFF
                }

                .side-nav-li-common-list-style {
                    list-style: none;
                    padding: 0px;
                    cursor: pointer;
                    border-bottom: 1px solid lightgray;
                    /* &:first-child {
                        border-radius: 5px 0px 0px 0px;
                    }*/
                    li {
                        &:hover {
                            background: #EDEDED;
                        }
                    }

                    span {
                        &:hover {
                            text-decoration: underline;
                            background: #EDEDED;
                            font-weight: bold;
                        }
                    }

                    &.active {

                        .side-subnav-ul-common-list-style {
                            background: #FFFFFF !important;
                        }
                    }

                    &.active-subnav {
                        span {
                            font-weight: bold;
                            color: #000000 !important;
                        }
                    }

                    &.active-subnav {
                        background-color: #00CAFB;
                    }
                    /*&:hover {
                        background: #EDEDED;
                    }*/

                    &.disabled {
                        color: #6c757d;
                        pointer-events: none;
                        background-color: #EDEDED;
                    }

                    .submenu-helper-text {
                        font-size: 12px;
                        color: #939598;
                    }

                    .sensitivity-table-main-form {
                        .form-group {
                            margin-bottom: 0.1rem;
                        }
                    }

                    .side-subnav-ul-common-list-style {
                        .side-subnav-li-common-list-style {
                            padding: 0px 0px !important;
/*                            border-bottom: 1px solid lightgray;
*/                        }
                    }
                    /*&:last-child {
                        border-bottom: none !important;
                    }*/
                }

                padding-left: 0px;

                &:first-child {
                    border-radius: 5px 0px 0px 0px;
                }
            }
        }

        .navigation-content-col {
            padding-left: 0;
        }
    }
}
.sens-opt-tables {
    background-color: #EDEDED;
    font-weight: bold;
}
