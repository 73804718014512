.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 12px;
    font-family: 'Segoe UI';
    font-weight: 400;
    min-width: 400px;
    border: 1px solid;
    border-color: #DEE2E6;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr th {
    background-color: gray;
    color: black;
    text-align: left;
    border: 1px solid;
    border-color: black;
    padding: 5px;
}
.styled-table > thead > tr > th {
    vertical-align: middle;
}
.styled-table th,
.styled-table td {
    padding: 5px;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Segoe UI';
    border: 1px solid;
    border-color: black;
    min-width: 150px;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}
.styled-table tbody tr.selected {
    background-color: red;
}
.styled-table tbody td.active-row {
    font-weight: bold;
    background-color: #00CAFB;
    color: #ffffff;
}
